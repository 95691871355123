<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
    },
}
</script>

<template>
    <div>
        <BaseLabel :name="field.name" :label="field.label" />
        <input
            :id="field.name"
            :value="value"
            :name="field.name"
            :type="field.type"
            :placeholder="field.placeholder"
            :required="field.required"
            class="input"
            v-on="listeners"
        />
    </div>
</template>
